<template>
    <div class="content-wrapper">
        <rq-page-section title="Buyer & Seller Contacts" header-size="lg" header-only borderless>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">Configure and define Buyer & Seller Contacts.</div>
            </template>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button v-if="!selectMode" class="btn btn-theme" type="button" aria-expanded="false" @click="onAddContact">Add</button>
                        </div>
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rq-page-section header-size="lg" borderless>
            <div class="row">
                <div class="col col-3 form-group">
                    <label class="form-control-label" for="txt_buyer_seller_contact_search">Search:</label>
                    <rq-search-input-group
                        id="txt_buyer_seller_contact_search"
                        placeholder="Search..."
                        v-model="searchValue"
                        show-search-button
                        search-on-enter
                        @search="onSearch"
                        @clear="onSearchChange"
                    />
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <label for="dtp_region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_region', id: 'dtp_region' }"
                        :items="regions"
                        value-expr="regionID"
                        display-expr="displayName"
                        :show-clear-button="true"
                        @value-changed="onRegionFilterChange"
                        @clear="onRegionFilterChange"
                        v-model="regionValue"
                    />
                </div>
                <slot name="search-row"></slot>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
        ref="dataGrid"
        automation_id="tbl_buyer_seller_contacts"
        :data-source="gridDataSource"
        :config="gridConfig"
        :actions="selectionActions"
        @activate="onActivateItem"
        @edit="onEditItem"
        @selectionChanged="onGridSelectionChanged"
        :from-config="fromConfig"
        :single-selection-enabled="selectMode"
        :show-include-inactive="!selectMode"
        target-inactive-column="isInactive"
        :strikethrough-if-true="['isInactive']"
        :search-term="searchValue"
        :search-region="regionValue"
        integrated-search
        remote-search
        hide-search
        rq-filters>
    </rqdx-action-data-grid>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import BuyerSellerForm from "../../../file/order-entry/buyer-seller/BuyerSellerForm.vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { BuyerSellerDto } from "@/modules/file/order-entry/buyer-seller/models.js";

    const BuyerSellerType = {
        BUYER: 0,
        SELLER: 1,
        TEMPLATE:2
    }

    export default {
        name: "CompanyContacts",
        props: {
            companyId: { type: Number, default: 0 },
            regionId: { type: Number, default: 0 },
            companyName: { type: String, default: null },
            companySalesRepStaffId: { type: Number, default: null },
            showOrderLinks: { type: Boolean, default: false },
            showSettlementLinks: { type: Boolean, default: false },
            fromConfig: { type: Boolean, default: true },
            selectMode: { type: Boolean, default: false },
            searchTerm: { type: String, default: "" },
            searchRegion: { type: String, default: "" }

        },
        data: function() {
            const self = this;
            return {
            items: [],
            selectedItem: {},
            itemKey: "buyerSellerID",
            itemTypeName: "Buyer/Seller Contact",
            itemTypeNamePlural: "Buyer/Seller Contacts",
            searchValue: self.searchTerm,
            regionValue: self.searchRegion,
            }
        },
        computed: {
            ...mapGetters([ "userDefaultRegion", "permissionService" ]),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            localSecurity() {
                 return this.securitySettings.findValues([
                     "AllowEditContacts"
                ]);
            },
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null) || {}; },
            regions() { return this.lookupHelpers.getRegions(); },
            selectionActions() {
                if(this.selectMode){
                    return;
                }
                return [
                    { name: "edit", text: "Edit", eventName: "edit", allowMultiSelection: true, tooltip: `Edit Contact` },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate Contact`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['isInactive', true]); } },
                    { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate Contact`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['isInactive', false]); }  }
                ];
            },

        },
        created(){
            this.initGridConfig();
        },
        methods: {
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: self.itemKey,
                            dataType: "number",
                            caption: "Buyer/Seller ID",
                            visible: true,
                            showInColumnChooser: true,
                        },
                        {
                            dataField: "regionID",
                            caption: "Region",
                            calculateSortValue: "regionDisplay",
                            dataType: "number",
                            lookup: {
                                dataSource: self.regions,
                                displayExpr: "displayName",
                                valueExpr: "regionID",
                            },
                            visible: false,
                            showInColumnChooser: true,
                        },
                        {
                            dataField: "firstName1", caption: "First Name", dataType: "string"
                        },
                        {
                            dataField: "lastName1", caption: "Last Name", dataType: "string"
                        },
                        { dataField: "emailAddress1", dataType: "string", caption: "Email" },
                        { dataField: "cellPhone1", dataType: "string", caption: "Cell Phone" },
                        { dataField: "currentAddress1", dataType: "string", caption: "Address"},
                        { dataField: "currentCity", dataType: "string", caption:"City"},
                        { dataField: "currentState", dataType: "string", caption: "State" },
                        { dataField: "currentZip", dataType: "string" },
                        { dataField: "formalName", caption: "Formal Name", dataType: "string", visible: false, showInColumnChooser: true},
                        { dataField: "isInactive", caption: "Inactive", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate,visible: false },
                    ],
                };
                self.gridDataSource = {
                    key: self.itemKey,
                    load: self.fetchData,
                };
            },
            
            onRegionFilterChange(e){
                if(_.isNullOrEmpty(e.value)){
                    this.gridInstance.clearFilter();
                }
                else{
                    this.gridInstance.filter(['regionID', '=', e.value])
                }
            },
            
            onSearchChange() { this.gridInstance.searchByText(""); this.refresh(); },

            onSearch: _.debounce(function (e) {

                this.gridInstance.searchByText(this.searchValue);
            }, 300, {trailing: true, leading: false}),

            fetchData() {
                const self = this;
                self.maxSequence = 0;
                let apiPromise = self.$api.BuyerSellerContactApi.getAll();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let items = _.map(result, i => new BuyerSellerDto(i));
                        self.items = items;
                        return { data: items, totalCount: items.length };
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },
            onAddContact(roleTypeId) { 
                const self = this;
                let onOk = (e) => {
                    e.component.v$.$touch();
                    if (e.component.v$.$error) {
                        e.component.isValid();
                        return false;
                    }
                    return e.component.saveBuyerSellerContactTemplate()
                        .then(() => {
                        self.$toast.success("Contact updated succesfully.")
                            self.fetchData();
                            self.refresh();
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error("Error saving Contact.");
                            console.error(error);
                            return error;
                        })
                }
                self.$dialog.open({
                    title: "Add Contact",
                    height: "85%",
                    width: "85%",
                    adaptive: true,
                    component: BuyerSellerForm,
                    props: {
                        fromConfig: true
                    },
                    onOk:onOk,
                    onCancel (e) { return true;}
                });
            },
        
            onEditItem(e) { 
                const self = this;
                let buyerSellerID = e.data[0].buyerSellerID;
                let onOk = (e) => {
                    e.component.v$.$touch();
                    if (e.component.v$.$error) {
                        e.component.isValid();
                        return false;
                    }
                    return e.component.saveBuyerSellerContactTemplate()
                        .then(() => {
                        self.$toast.success("Contact updated succesfully.")
                            self.fetchData();
                            self.refresh();
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error("Error saving Contact.");
                            console.error(error);
                            return error;
                        })
                }
                self.$dialog.open({
                    title: "Edit Contact",
                    height: "85%",
                    width: "85%",
                    adaptive: true,
                    component: BuyerSellerForm,
                    props: {
                        fromConfig: true,
                        templateBuyerSellerID: buyerSellerID
                    },
                    onOk: onOk,
                    onCancel (e) { return true;}
                });
            },
            onGridSelectionChanged(e) {
                this.selectedItem = e.selectedRowsData[0];

            },

            activate(keys, verb) {
                const self = this;
                let apiPromise = self.$api.BuyerSellerContactApi.activate(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.toggleInactiveFlag(keys);
                        self.refresh();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            onActivateItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['isInactive', true]) ? "Activate" : "Inactivate";
                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.activate(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            toggleInactiveFlag(keys) {
                _.each(keys, k => {
                    let e = _.find(this.items, [this.itemKey, k]);
                    e.isInActive = !e.isInActive;
                });
            },
            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },
        }
    }
</script>
