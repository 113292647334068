<template>
    <div class="content-wrapper">
        <rq-page-section title="Staff" header-size="lg" borderless>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">Define staff to specific job functions.</div>
            </template>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn automation_id="btn_add" variant="theme" @click="onAddItem">Add</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn automation_id="btn_add" variant="theme" @click="onAddFromUser">Add From User</b-btn>
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            target-inactive-column="isInactive"
            :strikethrough-if-true="['isInactive']"
            @delete="onDeleteItem"
            @signature="onLoadSignature"
            @notary="onLoadNotary"
            @activate="onActivateItem"
            show-include-inactive
            integrated-search
            hide-show-column-chooser
            rq-editable
            rq-filters
        />
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { StaffDto, NotaryDto }  from "../models";
    import { JobTypes } from '../../enums';
    import StaffSignature  from "./StaffSignature.vue";
    import StaffNotary from "./StaffNotary.vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        data () {
            return {
                items: [],
                originalData: {},
                validationErrors: [],
                addEventName: "",
                users: [],
                availableUsers: [],
                errorMessage: "",
                gridConfig: {},
                gridDataSource: {},
                staffRegionMappings: []
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            jobTypes() { return  JobTypes.lookupItems; },
            regions() { return this.lookupHelpers.getRegions(); },
            hasError() { return _.get(this, "validationErrors.length", 0) > 0; },
            errorMsg(){ return this.hasError ?  "Please correct the highlighted errors on screen to continue." : ""; }
        },
        watch: {
            validationErrors() {
                const self = this;
                self.$events.emit("update-config-error", {
                    message: "Please correct the highlighted errors on screen to continue.",
                    hasError: self.hasError
                });
            }
        },
        created(){
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.fetchData();
            this.initListeners();
        },
        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        methods: {

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            initNonReactiveVariables() {
                this.itemTypeName = "Staff Member";
                this.itemTypeNamePlural = "Staff Members";
                this.itemKey = "staffID";

                this.selectionActions = [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${this.itemTypeName}`
                    },
                    {
                        name: "signature",
                        text: "Signature",
                        eventName: "signature",
                        requireSelection: true,
                        allowMultiSelection: false,
                    },
                    {
                        name: "notary",
                        text: "Notary",
                        eventName: "notary",
                        requireSelection: true,
                        allowMultiSelection: false,
                        disabled(e) {
                            let selectedItems = _.get(e, "data", null) || [];
                            if(_.findIndex(selectedItems.jobDutyIDs, item => item == -7) >= 0) { // notary
                                return false;
                            }
                            return true;
                        }
                    },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['isInactive', true]); } },
                    { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['isInactive', false]); } }
                ];
            },

            initGridConfig() {
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "name",
                            caption: "Name",
                            dataType: "string",
                            editCellTemplate: function(cellElement, cellInfo) {
                                self.generateCustomEditor(cellElement, cellInfo, "text", 50);
                            },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isNotDuplicateName,
                                    message: "A Staff member already exists with this Name in the selected Region"
                                }
                            ],
                        },
                        {
                            dataField: "title",
                            dataType: "string",
                            caption: "Title",
                            editCellTemplate: function(cellElement, cellInfo) {
                                self.generateCustomEditor(cellElement, cellInfo, "text", 50);
                            },
                        },
                        {
                            dataField: "initials",
                            dataType: "string",
                            caption: "Initials",
                            validationRules: [{ type: "required" }],
                            editCellTemplate: function(cellElement, cellInfo) {
                                self.generateCustomEditor(cellElement, cellInfo, "text", 5);
                            },
                        },
                        {
                            dataField: "regionIDs",
                            dataType: "object",
                            caption: "Region",
                            width: 300,
                            minWidth: 300,
                            calculateSortValue: rowData => {
                                let regionNames = _.sortBy(_.map(_.filter(self.regions, r => _.includes(rowData.regionIDs, r.regionID)), "description"));
                                return _.join(regionNames, "");
                            },
                            calculateFilterExpression: (filterValue, operator) => {
                                if (_.isString(filterValue)) {
                                    return rowData => {
                                        let regionNames = _.sortBy(_.map(_.filter(self.regions, r => _.includes(rowData.regionIDs, r.regionID)), "description"));
                                        return _.includes(_.toLower(regionNames), _.toLower(filterValue))
                                    }
                                }

                                if(operator !== "contains" || !this.isValidPhoneNumber(filterValue)) return () => false;

                                return rowData => {
                                        let ids = [rowData.regionID, ...rowData.regionIDs];
                                        return _.includes(ids, filterValue);
                                }
                            },
                            rqFilter: {
                                valueExpr: "regionID",
                                displayExpr: "description",
                                dataSource: _.sortBy(self.regions, "description"),
                                listOperator: "and",
                                valueOperator: "contains",
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                let regions = self.regions;
                                if(_.isEmpty(cellInfo.value) || _.isEmpty(regions)) return;


                                let itemNames = _.sortBy(_.map(cellInfo.value, id =>
                                                        _.find(regions, r => id == r.regionID)?.description
                                                        ).filter(ele => ele != undefined));
                                let displayText = _.joinParts(itemNames, ", ");
                                let displayTitle = itemNames.length === 1
                                    ? displayText
                                    : `${itemNames.length} Regions Selected`;
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayTitle)
                                    .append(displayText)
                                    .appendTo(cellElement);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxTagBox({
                                    dataSource: {
                                        loadMode: "raw",
                                        load() {
                                            return self.regions;
                                        }
                                    },
                                    displayExpr: "description",
                                    valueExpr: "regionID",
                                    value: cellInfo.value,
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    searchEnabled: false,
                                    maxDisplayedTags: 1,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isNotDuplicateName,
                                    message: "A Staff member already exists with this Name in the selected Region"
                                }
                            ],
                            setCellValue(rowData, value) {
                                rowData.regionIDs = value;
                                rowData.regionID = value?.[0];
                            }
                        },
                        {
                            dataField: "eMailAddress",
                            dataType: "string",
                            caption: "Email",
                            editCellTemplate: function(cellElement, cellInfo) {
                                self.generateCustomEditor(cellElement, cellInfo, "text", 150);
                            },
                        },
                        {
                            dataField: "jobDutyIDs",
                            dataType: "object",
                            caption: "Job Function",
                            width: 300,
                            minWidth: 300,
                            calculateSortValue: rowData => {
                                let jobTypeNames = _.sortBy(_.map(_.filter(self.jobTypes, r => _.includes(rowData.jobDutyIDs, r.id)), "name"));
                                return _.join(jobTypeNames, "");
                            },
                            calculateFilterExpression: (filterValue, operator) => {
                                if (_.isString(filterValue)) {
                                    return rowData => {
                                        let jobTypeNames = _.sortBy(_.map(_.filter(self.jobTypes, r => _.includes(rowData.jobDutyIDs, r.id)), "name"));
                                        return _.includes(_.toLower(jobTypeNames), _.toLower(filterValue))
                                    }
                                }

                                if(operator !== "contains" || !this.isValidPhoneNumber(filterValue)) return () => false;

                                return rowData => {
                                        let ids = [rowData.jobDutyIDs, ...rowData.jobDutyIDs];
                                        return _.includes(ids, filterValue);
                                }
                            },
                            rqFilter: {
                                valueExpr: "id",
                                displayExpr: "name",
                                dataSource: _.sortBy(self.jobTypes, "name"),
                                listOperator: "and",
                                valueOperator: "contains",
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                let jobTypes = JobTypes.lookupItems;
                                if(_.isEmpty(cellInfo.value) || _.isEmpty(jobTypes)) return;
                                let itemNames = _.sortBy(_.map(cellInfo.value, id => _.find(jobTypes, { id }).name));
                                let displayText = _.joinParts(itemNames, ", ");
                                let displayTitle = itemNames.length === 1
                                    ? displayText
                                    : `${itemNames.length} Job Types Selected`;
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayTitle)
                                    .append(displayText)
                                    .appendTo(cellElement);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxTagBox({
                                    dataSource: {
                                        loadMode: "raw",
                                        load() {
                                            return JobTypes.lookupItems;
                                        }
                                    },
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.value,
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    searchEnabled: false,
                                    maxDisplayedTags: 1,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            },
                            validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "usersID",
                            dataType: "number",
                            caption: "User",
                            editorOptions: { disabled: true },
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: {
                                    loadMode: "raw",
                                    load: () => _.sortBy(self.users, "name")
                                }
                            },
                            calculateSortValue: rowData => {
                                let user = _.find(self.users, u => u.id === rowData.usersID);
                                return _.trim(user?.name);  // Restored name as the user name displayed, not "fullName".
                            },
                        },
                        {
                            dataField: "directPhone",
                            dataType: "string",
                            caption: "Phone",
                            editCellTemplate: function(cellElement, cellInfo) {
                                self.generateCustomEditor(cellElement, cellInfo, "phone-text");
                            },
                            validationRules: [
                                {
                                    type: "custom",
                                    validationCallback: self.isValidPhoneNumber,
                                }
                            ]
                        },
                        {
                            dataField: "cellPhone",
                            dataType: "string",
                            caption: "Mobile",
                            editCellTemplate: function(cellElement, cellInfo) {
                                self.generateCustomEditor(cellElement, cellInfo, "phone-text");
                            },
                            validationRules: [
                                {
                                    type: "custom",
                                    validationCallback: self.isValidPhoneNumber,
                                }
                            ]
                        },
                        {
                            dataField: "fax",
                            dataType: "string",
                            caption: "Fax",
                            editCellTemplate: function(cellElement, cellInfo) {
                                self.generateCustomEditor(cellElement, cellInfo, "phone-text");
                            },
                            validationRules: [
                                {
                                    type: "custom",
                                    validationCallback: self.isValidPhoneNumber,
                                }
                            ]
                        },
                        {
                            dataField: "isInactive",
                            caption: "Inactive",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        }
                    ],
                };
                self.gridDataSource = {
                    key: self.itemKey,
                    load: async () => self.items,
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            onAddItem() {
                this.gridInstance?.addRow()
            },

            onAddFromUser() {
                this.showUserSelection();
            },

            generateCustomEditor(cellElement, cellInfo, type, maxLength = 0){
                const self = this;
                let readOnly = cellInfo.row.data.createdFromUserCopy || _.parseNumber(cellInfo.row.data.usersID, 0) > 0;
                let $tooltip = $("<span/>")
                    .append(`Return to Users & Security <br> Roles to manage user.`)
                    .dxTooltip({
                        target:  cellElement,
                        wrapperAttr: { class: "rq-dx-tooltip" },
                        position: "top",
                        showEvent:"mouseenter",
                        hideEvent:"mouseleave",
                    });

                if(type === "text"){
                    $("<div />")
                        .dxTextBox({
                            value: cellInfo.value,
                            disabled: readOnly,
                            maxLength: maxLength,
                            onValueChanged: e => cellInfo.setValue(e.value)
                        })
                        .append(readOnly ? $tooltip : null)
                        .appendTo(cellElement);
                }
                else if(type === "phone-text"){
                    var currentValue = cellInfo.value?.replace(/\D/g,'');
                    $("<div />")
                        .dxTextBox({
                            value: currentValue,
                            disabled: readOnly,
                            mask: readOnly ? '' : '(XXX) XXX-XXXX',
                            maskRules: readOnly ? {} : { X: /[0-9 ]/},
                            onValueChanged: function(e) {
                                if (!_.isEmpty(e.value)) cellInfo.setValue(e.component._textValue);
                                else cellInfo.setValue(e.value);
                            }
                        })
                        .append(readOnly ? $tooltip : null)
                        .appendTo(cellElement);
                }
                else if(type === "select") {
                    $("<div />")
                        .dxSelectBox({
                            dataSource: {
                                loadMode: "raw",
                                load: () => self.users
                            },
                            displayExpr: "name",
                            valueExpr: "id",
                            value: cellInfo.data.usersID,
                            disabled: readOnly,
                            onValueChanged: e => cellInfo.setValue(e.value)
                        })
                        .append(readOnly ? $tooltip : null)
                        .appendTo(cellElement);
                }
            },

            onDeleteItem(e) {
                if(!e?.data) return;
                const self = this;
                let items = e.data;
                const ok = async () => {
                    let toBeDeletedKeys = _.map(items, "staffID");
                    return await self.delete(toBeDeletedKeys);
                };
                let itemLabel = items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName;
                self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${itemLabel}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            isNotDuplicateName(item) {
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => {
                    let regions = _.intersection(i.regionIDs, item.data.regionIDs);
                    return _.toLower(_.trim(i.name)) === _.toLower(_.trim(item.data.name))
                            && regions.length > 0
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(item.data, self.itemKey, -1), -1);
                });

                return dup ? false : true;
            },

            isValidPhoneNumber(e){
                let phoneNum = /^\(\d{3}\)\s\d{3}-\d{4}$/;
                return _.getBool(e, "data.createdFromUserCopy")
                    || _.isEmpty(e.value)
                    || phoneNum.test(e.value);
            },

            async fetchData() {
                const self = this;
                self.staffRegionMappings = [];
                const loadData = async () => {
                    let apiPromises = [
                        self.$api.StaffApi.getStaff(),
                        self.$api.UsersApi.getUsers(),
                        self.$api.StaffApi.getAvailableUsers()
                    ];
                    try {
                        let result = await Promise.all(apiPromises);
                        let regions = self.regions;
                        self.items = _.map(result[0], i => {
                            var item = new StaffDto(i);
                            const regionIDsNotPresentInLookup = item.regionIDs.filter(element => !regions.some(reg => reg.regionID == element));
                            
                            if(!_.isNil(regionIDsNotPresentInLookup) && regionIDsNotPresentInLookup.length > 0) {
                                self.staffRegionMappings.push({
                                    staffID: item.staffID,
                                    removedRegionIDs: regionIDsNotPresentInLookup
                                });

                                // Remove the regionIDs that user doesnt have access to
                                item.regionIDs = item.regionIDs.filter(element => !regionIDsNotPresentInLookup.includes(element));
                            }
                            
                            return item;
                        });
                        self.users = _.map(result[1], i => ({name: i.fullName, id: i.usersID  }));
                        self.availableUsers = result[2];
                    }
                    catch(error) {
                        self.items = [];
                        self.$toast.error(`Error loading users.`);
                    }
                    finally {
                        self.refresh();
                    }
                };
                await self.$rqBusy.wait(loadData());
            },
            refresh() {
                this.gridInstance?.clearSelection();
                this.gridInstance?.refresh();
            },

            async onGridInsert(values) {
                const self = this;
                let newItem = (new StaffDto(values)).toDataObject();
                let originalItem = (new StaffDto()).toDataObject();
                let changes = self.getAuditChanges(originalItem, newItem, ["regionIDs"], ["jobDutyIDs"]);
                await self.save(newItem, changes);
            },

            async onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.staffID === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalData = self.items?.[itemIndex]?.toDataObject();
                let itemData = (new StaffDto(_.assign({}, originalData, values))).toDataObject();

                // Restore the previously removed Region IDs during fetch call
                let originalRegionMapping = self.staffRegionMappings.find(element => element.staffID == itemData.staffID);
                if(!_.isNil(originalRegionMapping)) {
                    itemData.regionIDs = itemData.regionIDs.concat(originalRegionMapping.removedRegionIDs);
                    if(!_.isNil(originalData)) {
                        originalData.regionIDs = originalData.regionIDs.concat(originalRegionMapping.removedRegionIDs);
                    }
                }

                let changes = self.getAuditChanges(originalData, itemData);
                if (changes.length == 0) return;
                await self.save(itemData, changes);
            },

            onActivateItem(e){
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['isInactive', true]) ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.toggleIsInactive(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            async toggleIsInactive(keys, verb) {
                const self = this;
                try {
                    let apiPromise = self.$api.StaffApi.toggleIsInactive(keys);
                    await self.$rqBusy.wait(apiPromise);
                    await self.fetchData();
                    let message = keys.length > 1
                        ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                        : `${self.itemTypeName} was ${verb}d.`
                    self.$toast.success(message);
                    return true;
                }
                catch(error) {
                    self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                    console.error(error);
                    return error;
                }
            },

            async save(item, changes){
                const self = this;
                return await self.processSaveRequest({
                    apiCall: () => self.$api.StaffApi.saveStaff(item, changes),
                    refresh: true
                });
            },

            async addFromUser(items){
                const self = this;
                return await self.processSaveRequest({
                    apiCall: () => self.$api.StaffApi.addFromUser(items),
                    refresh: true
                });
            },

            async delete(ids) {
                const self = this;
                try {
                    let apiPromise = self.$api.StaffApi.deleteStaff(ids);
                    let key = await self.$rqBusy.wait(apiPromise);
                    self.fetchData();
                    self.$toast.success(key.length > 1
                        ? `${key.length} ${self.itemTypeNamePlural} were deleted.`
                        : `${self.itemTypeName} was deleted.`);
                    return true;
                }
                catch(error) {
                    if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                        self.$dialog.messageBox(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                    } else {
                        self.$toast.error(`Error deleting ${self.itemTypeName}.`);
                    }
                    return error;
                }
            },

            showUserSelection() {
                const self = this;
                self.$dialog.promptMultiSelect({
                    title: "Select User",
                    label: "User:",
                    items: self.availableUsers,
                    valueExpr: "id",
                    displayExpr: "name",
                    requiredMessage: "User is required",
                    onOk: e => {
                        self.addFromUser(e.selectedValues);
                        return true;
                    }
                });
            },

            onLoadSignature(e) {
                const self = this;
                let originalData = new StaffDto(e.data);
                let item = new StaffDto(e.data);
                self.$dialog.open({
                    title: "Edit Signature Image",
                    height: 450,
                    width: 500,
                    resizable: true,
                    closeOnEsc: true,
                    component: StaffSignature,
                    props: { item },
                    onOk(e2) {
                        if(!e2.component.imageChanged) return true;
                        let itemData = item.toDataObject();
                        let changes = self.getAuditChanges(originalData.toDataObject(), itemData);
                        self.save(itemData, changes);
                        return true;
                    }
                });

            },

            onLoadNotary(e) {
                const self= this;
                self.originalData = new NotaryDto(e.data.notary);
                self.$dialog.open({
                    title: `${self.originalData.isNew ? "Add": "Edit"} Notary`,
                    height: "600",
                    width: "650",
                    resizable: true,
                    scrollable: true,
                    component: StaffNotary,
                    props: {
                        item: new NotaryDto(e.data.notary),
                        itemTypeName: self.itemTypeName
                    },
                    okTitle: "Save",
                    onOk: e => self.saveNotary(e.component.item)
                });

            },
            async saveNotary(item){
                const self= this;
                let originalData = self.originalData.toDataObject();
                let itemData = item.toDataObject();
                let changes = self.getAuditChanges(originalData, itemData);
                 if (changes.length == 0) {
                    self.$toast.info("No changes detected");
                    return;
                }
                return await self.processSaveRequest({
                    apiCall: () => self.$api.StaffApi.saveNotary(itemData, changes),
                    refresh: true
                });
            },

            /*
                TG - Noticed the same identical block being used in several areas, so consolidated it into a single wrapper method.
                Made what ultimately returns the api promise an arrow function so it could be included within the try/catch.
            */
            async processSaveRequest({ apiCall=async () => true, refresh=false }={ apiCall:async () => true, refresh:false }) {
                const self = this;
                let result = null;
                try {
                    let apiPromise = apiCall();
                    result = await self.$rqBusy.wait(apiPromise);
                    self.$toast.success(`${self.itemTypeName} was saved.`);
                }
                catch(error) {
                    self.$toast.error(`Error saving ${self.itemTypeName}.`);
                    result = error;
                }
                if(refresh) await self.fetchData();
                return result;
            }
        }
    }
</script>
